import DamApi from '../../api/dam'
import CoreApi from '../../api/core'
import MediaService from '../../services/media/MediaService'

export default {
  methods: {
    async extractFromUrl (url) {
      return new Promise((resolve, reject) => {
        DamApi().post('/metadata/image/url', { url: url })
          .then((response) => {
            const file = {
              fileBase64: response.data.base64Image,
              metadata: {
                caption: response.data.metadata.caption,
                author: response.data.metadata.byLine,
                keywords: response.data.metadata.keywords
              }
            }
            this.uploadFile(file)
              .then(() => {
                resolve({
                  done: 'DONE'
                })
              })
          })
          .catch(error => {
            console.log(error)
          })
      })
    },
    async uploadFile (file) {
      const bodyFormData = new FormData()
      bodyFormData.set('image', MediaService.dataUriToBlob(file.fileBase64))
      bodyFormData.set('caption', file.metadata.caption)
      bodyFormData.set('byLine', file.metadata.author)
      bodyFormData.set('keywords', file.metadata.keywords)
      await CoreApi().post('/dam/upload', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(async response => {
          this.video.previewImage = response.data.id
          this.video.expanded.previewImage = response.data
          this.$emit('save')
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
