<template>
  <section>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="form-edit-header">{{ $t('video.annotations') }}</h3>
          </div>
          <div class="col-lg-6 text-right">
            <button
              class="btn btn-sm btn-info"
              data-test="video_annotation_btn_new"
              @click="createNewAnnotation('external')"
            >
              <i class="fa fa-plus"></i> {{ $t('video.annotation_web') }}
            </button>
            <button
              class="btn btn-sm btn-info"
              data-test="video_annotation_btn_new"
              @click="createNewAnnotation('video')"
            >
              <i class="fa fa-plus"></i> {{ $t('video.annotation_video') }}
            </button>
            <button
              class="btn btn-sm btn-info"
              data-test="video_annotation_btn_new"
              @click="createNewAnnotation('article')"
            >
              <i class="fa fa-plus"></i> {{ $t('video.annotation_article') }}
            </button>
          </div>
        </div>
        <p class="alert alert-info" v-if="video.annotations.length === 0">
          {{ $t('video.annotations_not_found') }}
        </p>
        <div class="row" v-if="video.annotations.length > 0">
          <div class="col-lg-12">
            <app-video-annotation-gantt
              :video="video"
            >
            </app-video-annotation-gantt>
          </div>
        </div>
        <div class="table-responsive" v-if="video.annotations.length > 0">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{ $t('video.annotation_title') }}</th>
              <th>{{ $t('video.annotation_type') }}</th>
              <th>{{ $t('video.annotation_position') }}</th>
              <th>{{ $t('video.annotation_start') }}</th>
              <th>{{ $t('video.annotation_end') }}</th>
              <th width="180">{{ $t('actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(annotation, index) in video.annotations" :key="`item-${index}`">
              <td>
                {{ annotation.title }}<br>
                <small>
                  <strong>{{ $t('video.annotation_url') }}: </strong>{{ annotation.url }}
                </small>
                <small v-if="annotation.ooyalaId">
                  <br><strong>{{ $t('video.annotation_video_id') }}: </strong>{{ annotation.ooyalaId }}
                </small>
                <app-annotation-stats :annotation="annotation"></app-annotation-stats>
              </td>
              <td>{{ annotation.type }}</td>
              <td>{{ annotation.position }}</td>
              <td>{{ annotation.start | formatMilliseconds }}</td>
              <td>{{ annotation.end | formatMilliseconds }}</td>
              <td>
                <router-link
                  tag="a"
                  class="btn btn-default btn-sm"
                  :to="{ name: 'videoAnnotation_edit_old', params: { id: video.id, annotationId: annotation.id }}"
                  data-test="video_annotation_btn_edit"
                >
                  <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
                </router-link>
                <button
                  type="button"
                  class="btn btn-default btn-sm"
                  :title="$t('buttons.delele')"
                  @click="showAnnotationDeleteModal(annotation)"
                >
                  <i class="fa fa-trash"></i> {{ $t('buttons.delete_button') }}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <app-video-annotation-delete-modal
      :annotation="selectedAnnotation"
      v-if="annotationDeleteModal"
      @delete-record="removeAnnotation"
      @close="hideAnnotationDeleteModal"
    >
    </app-video-annotation-delete-modal>
  </section>
</template>

<script>
import VideoAnnotationDeleteModal from './VideoAnnotationDeleteModal'
import NotifyService from '../../services/NotifyService'
import VideoAnnotationGantt from './VideoAnnotationGantt'
import VideoAnnotationModel from '../../model/VideoAnnotation'
import AnnotationStats from './AnnotationStats'

export default {
  name: 'VideoAnnotationList',
  props: {
    video: {
      type: Object
    }
  },
  data () {
    return {
      annotationDeleteModal: false,
      selectedAnnotation: null,
      videoAnnotation: VideoAnnotationModel
    }
  },
  components: {
    appVideoAnnotationDeleteModal: VideoAnnotationDeleteModal,
    appVideoAnnotationGantt: VideoAnnotationGantt,
    appAnnotationStats: AnnotationStats
  },
  methods: {
    showAnnotationDeleteModal (annotation) {
      this.selectedAnnotation = annotation
      this.annotationDeleteModal = true
    },
    hideAnnotationDeleteModal () {
      this.annotationDeleteModal = false
    },
    createNewAnnotation (type) {
      this.videoAnnotation.type = type
      this.$router.push('/videoOld/' + this.video.id + '/annotation/new')
    },
    prepareRequest (annotation) {
      annotation.video = parseInt(this.video.id)
      return annotation
    },
    removeAnnotation (annotation) {
      this.$store.dispatch('videoAnnotation/deleteRecord', this.prepareRequest(annotation))
        .then(() => {
          NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
          this.$emit('change')
        })
    }
  }
}
</script>
