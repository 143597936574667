<template>
  <app-modal
    :title="$t('modal.media_upload_header_2')"
    modalSize="modal-md"
    @close="close"
  >
    <template slot="body">
      <div class="m-b-40">
        <h5 class="text-center" v-if="preparing">
          <strong>{{ $t('video.preparing') }}</strong><br>
          {{ $t('video.sending') }}
        </h5>
        <h5 class="text-center" v-if="uploading">
          <strong>{{ $t('video.process') }}</strong><br>
          {{ $t('video.uploadIsStarting') }}<br>
          {{ $t('video.closeWindowInfo') }}
        </h5>
        <h5 class="text-center" v-if="finalizing">
          <strong>{{ $t('video.finalization') }}</strong><br>
          {{ $t('video.finalizationLivebox') }}<br>
          {{ $t('video.closeWindowInfo') }}
        </h5>
        <div class="progress m-t-40">
          <div
            class="progress-bar wow animated bg-success"
            :style="progressBarWidth"
            role="progressbar"
          >
            {{ progressBarCount }}%
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import CoreApi from '../../api/core'
import Modal from './Modal'
import liveboxApi from '../../api/livebox'
import NotifyService from '../../services/NotifyService'
import { uuid } from 'vue-uuid'

export default {
  name: 'LiveboxMultipartUploadModal',
  props: {
    files: {
      type: Array
    },
    thumbnailPosition: {
      type: String,
      default: '50'
    },
    video: {
      type: Object
    }
  },
  data () {
    return {
      preparing: false,
      uploading: false,
      finalizing: false,
      timeoutError: false,
      progressBarCount: 0,
      uploadUrl: '',
      apiVersion: '',
      apiKey: '',
      signKey: '',
      signature: '',
      liveboxAssetId: '',
      liveboxStatus: '',
      liveboxId: '',
      videoMetadata: null,
      thumbnailPositionResponse: ''
    }
  },
  computed: {
    progressBarWidth () {
      return 'width: ' + this.progressBarCount + '%; height:20px;'
    }
  },
  components: {
    appModal: Modal
  },
  methods: {
    close () {
      this.$emit('close')
    },
    assetName () {
      return uuid.v4()
    },
    load () {
      this.files.forEach(file => {
        const liveboxAssetId = this.assetName()
        this.prepareUpload(file, liveboxAssetId)
          .then(() => {
            this.uploadFile(file, liveboxAssetId)
              .then((success) => {
                if (success) {
                  this.finalizeUpload(liveboxAssetId)
                } else {
                  this.close()
                }
              })
            this.finalizing = false
          })
      })
    },
    async prepareUpload (file, assetName) {
      this.preparing = false
      const signatureRequestPayload = {
        liveboxAssetId: assetName,
        thumbnailPosition: this.thumbnailPosition === '' ? '50' : this.thumbnailPosition
      }
      return await CoreApi().post('/livebox/prepare_upload', JSON.stringify(signatureRequestPayload))
        .then(response => {
          this.apiKey = response.data.apiKey
          this.nonce = response.data.nonce
          this.apiVersion = response.data.apiVersion
          this.uploadUrl = response.data.uploadUrl
          this.signature = response.data.signature
          this.preparing = false
          this.thumbnailPositionResponse = response.data.thumbnailPosition
        })
        .catch(error => {
          console.log(error)
          this.preparing = false
        })
    },
    async uploadFile (file, assetName) {
      this.uploading = true
      const data = new FormData()

      data.append('file', file, file.name)
      data.append('file_name', 'file')
      data.append('ver', this.apiVersion)
      data.append('key', this.apiKey)
      data.append('target_archive', assetName)
      data.append('nonce', this.nonce)
      data.append('sig', this.signature)
      data.append('thumb_pct', this.thumbnailPositionResponse)

      return await liveboxApi().post(this.uploadUrl, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          if (response.status === 200) {
            this.progressBarCount = 100
            this.uploading = false
            this.liveboxAssetId = assetName
            this.liveboxId = response.data.id
            this.liveboxStatus = response.data.status
            this.videoMetadata = response.data.metadata
            return true
          }
          NotifyService.setErrorMessage(this.$t('video.video_upload_error_message'), '', -1)
          return false
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && this.timeoutError === false) {
            this.timeoutError = true
            NotifyService.setErrorMessage(this.$t('video.video_upload_error_message'), 'Timeout', -1)
          } else {
            NotifyService.setErrorMessage(this.$t('video.video_upload_error_message'), '', -1)
          }
          console.log(error)
          return false
        })
    },
    finalizeUpload (assetName) {
      this.finalizing = true
      const liveboxResponsePayload = {
        liveboxAssetId: assetName,
        liveboxId: this.liveboxId,
        liveboxStatus: this.liveboxStatus,
        metadata: this.videoMetadata
      }

      return CoreApi().post('/livebox/finalize_upload', JSON.stringify(liveboxResponsePayload))
        .then(() => {
          this.finalizing = false
          NotifyService.setSuccessMessage(this.$t('video.video_upload_success_message'))
          this.$emit('set-video', assetName)
          this.close()
        })
        .catch((error) => {
          this.finalizing = false
          console.log(error)
          NotifyService.setErrorMessage(this.$t('video.video_upload_error_message'), '', -1)
          this.$emit('set-video', assetName)
          this.close()
        })
    }
  },
  created () {
    this.load()
  }
}
</script>
