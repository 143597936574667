<template>
  <app-module-view>
    <template slot="body">
      <app-preloader-full v-if="!dataLoaded"></app-preloader-full>

      <!-- TOP BAR -------------------------------------------->
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-4">
            <app-button-copy-to-clipboard
              v-if="video.id"
              label="ID"
              :data="video.id | toString"
              data-test="video_btn_copy_to_clipboard"
              :notify-message="$t('notify.id_was_copied')"
            >
            </app-button-copy-to-clipboard>
            <app-video-check-status-button
              v-if="video.id"
              :video="video"
              @save="save(false)"
            >
            </app-video-check-status-button>
          </div>
          <div class="col-lg-4 text-right">
            <span>{{ $t('video.status') }}: </span>
            <app-video-status
              :video="video"
            >
            </app-video-status>
            <br v-if="video.statusMessage">
            <small class="text-danger" v-if="video.statusMessage">{{ video.statusMessage }}</small><br>
            <router-link
              v-if="video.mainArticleDocId"
              tag="a"
              :to="{name: 'articleByDocumentId', params: {id: video.mainArticleDocId}}"
              target="_blank"
              class="label label-warning article-info"
            >
              <i class="fas fa-info-circle"></i> {{ $t('video.video_article_exists') }}
            </router-link>
            <small>
              {{ $t('video.created_by') }}:
              <strong>{{ userNameById(video.createdBy) }} {{ video.createdAt | prettyDateTime }}</strong>
            </small>
          </div>
          <div class="col-lg-4">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save(false)"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="video.id" @deleteRecord="deleteVideo"></app-button-delete>
              <app-button-close route-name="video_list_old"></app-button-close>
            </div>
          </div>
        </div>
      </section>

      <!-- FORM -------------------------------------------->
      <section class="content form-horizontal">
        <div class="row">
          <div class="col-lg-8">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-4">
                    <h3 class="form-edit-header">{{ $t('video.main_content') }}</h3>
                  </div>
                  <div class="col-lg-8 text-right">
                    <label for="video_type" class="m-r-10">{{ $t('video.video_type_title') }}:</label>
                    <label
                      v-if="video.type === 'youtube'"
                      class="label label-inverse"
                    >
                      {{ $t('video.video_type_value.youtube') }}
                    </label>
                    <label
                      v-if="video.type === 'livebox'"
                      class="label label-inverse"
                    >
                      {{ $t('video.video_type_value.livebox') }}
                    </label>
                    <template v-if="false">
                      <label
                        v-for="(videoType, index) in videoTypeValues"
                        :key="`item-${index}`"
                        class="btn btn-secondary m-r-5"
                        :class="{ 'active': video.type === videoType.id }"
                      >
                        <input
                          type="radio"
                          v-model="video.type"
                          :value="videoType.id"
                          :data-test="'type_' + videoType.id"
                        >
                        {{ videoType.title }}
                      </label>
                    </template>
                  </div>
                  <div class="col-lg-12">
                    <input type="hidden" v-model="video.id">
                    <small
                      v-if="video.meta.title.length < 50"
                      class="form-control-feedback text-warning float-right"
                    >
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ $t('video.title_is_too_short') }}
                    </small>
                    <small
                      v-if="video.meta.title.length > 95"
                      class="form-control-feedback text-warning float-right"
                    >
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ $t('video.title_is_too_long') }}
                    </small>
                    <app-input
                      v-model.trim="video.meta.title"
                      @blur="$v.video.meta.title.$touch()"
                      :error="$v.video.meta.title.$error"
                      id="video_meta_title"
                      :label="$t('video.meta_title')"
                      required
                      show-counter
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-12">
                    <small
                      v-if="video.meta.description.length < 10"
                      class="form-control-feedback text-warning float-right"
                    >
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ $t('video.description_is_too_short') }}
                    </small>
                    <small
                      v-if="video.meta.description.length > 300"
                      class="form-control-feedback text-warning float-right"
                    >
                      <i class="fa fa-exclamation-triangle"></i>
                      {{ $t('video.description_is_too_long') }}
                    </small>
                    <app-textarea
                      v-model.trim="video.meta.description"
                      @blur="$v.video.meta.description.$touch()"
                      :error="$v.video.meta.description.$error"
                      id="video_meta_description"
                      :label="$t('video.meta_description')"
                      required
                      show-counter
                    >
                    </app-textarea>
                  </div>
                  <div class="col-lg-12">
                    <app-input
                      v-model.trim="video.meta.keywords"
                      @blur="$v.video.meta.keywords.$touch()"
                      :error="$v.video.meta.keywords.$error"
                      id="video_meta_keywords"
                      :label="$t('video.meta_keywords')"
                      required
                      show-counter
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-4">
                    <label :class="{'error': $v.video.expanded.authorUsers.$error}">
                      {{ $t('video.author') }}
                      <span class="required">*</span>
                    </label>
                    <app-multi-select
                      v-model="video.expanded.authorUsers"
                      :options="users"
                      label="username"
                      track-by="id"
                      id="video_authorUsers"
                      data-attr="authors_list"
                    >
                    </app-multi-select>
                  </div>
                  <div class="col-lg-4">
                    <label>{{ $t('video.camera') }}</label>
                    <app-multi-select
                      v-model="video.expanded.cameraUsers"
                      :options="users"
                      label="username"
                      track-by="id"
                      id="video_cameraUsers"
                      data-attr="cameraUsers_list"
                    >
                    </app-multi-select>
                  </div>
                  <div class="col-lg-4">
                    <label>{{ $t('video.editing') }}</label>
                    <app-multi-select
                      v-model="video.expanded.editingUsers"
                      :options="users"
                      label="username"
                      track-by="id"
                      id="video_editingUsers"
                      data-attr="editingUsers_list"
                    >
                    </app-multi-select>
                  </div>
                </div>
              </div>
            </div>

            <!--Livebox upload and preview ------------------------------------>
            <div
              class="card"
              v-if="video.liveboxAssetId !== '' || video.ooyalaId === ''"
            >
              <div class="card-body">
                <div class="row m-t-12">
                  <div class="col-lg-12 text-center">
                    <br/>
                    <div v-if="showVideoUploadButton === false" class="alert alert-info">
                      {{ $t('video.video_upload_required_fields_message') }}
                    </div>
                    <app-livebox-upload-button
                      v-if="video.status !== 'processing'"
                      v-show="showVideoUploadButton"
                      :video="video"
                      @set-video="setVideo"
                    >
                    </app-livebox-upload-button>
                  </div>

                  <div class="col-lg-12">
                    <div
                      class="col-lg-12"
                      v-if="video.liveboxAssetId !== '' && video.status === 'processing'"
                    >
                      <p class="alert alert-info">{{ $t('video.video_is_processing') }}</p>
                    </div>
                    <div
                      class="col-lg-12"
                      v-if="video.liveboxAssetId !== '' && ['live','published'].some(v => v === video.status)"
                    >
                      <app-eagle-video
                        :video="video"
                      >
                      </app-eagle-video>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Annotations ---------------------------------------->
            <div
              class="card"
              v-if="['livebox'].some(v => v === video.type) && ['live','published'].some(v => v === video.status)"
            >
              <div class="card-body">
                <app-video-annotation-list
                  v-if="dataLoaded"
                  :video="video"
                  @change="getVideo"
                >
                </app-video-annotation-list>
              </div>
            </div>

            <!-- Preview Images ------------------------------------>
            <div
              class="card"
              v-if="['live','published'].some(v => v === video.status)"
            >
              <div class="card-body">
                <h3 class="form-edit-header">{{ $t('video.headline_preview_image') }}</h3>
                <div class="row m-t-10">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>{{ $t('video.preview_image') }}</label><br>
                      <app-media-select-button
                        @set-media="setImage"
                      >
                      </app-media-select-button>
                      <app-media-upload-button
                        @set-media="setImage"
                      >
                      </app-media-upload-button>
                      <app-media-url-button
                        @set-media="setImage"
                      >
                      </app-media-url-button>
                      <button
                        v-if="video.youtubeId"
                        class="btn btn-sm btn-info"
                        title="Youtube thumbnail"
                        @click="setPreviewImageFromYoutube"
                      >
                        <i class="fab fa-youtube"></i> {{ $t('buttons.image_from_youtube') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <app-media-editable
                      v-if="video.previewImage"
                      :media="video.expanded.previewImage"
                      :width="360"
                      :height="202"
                      @remove-media="removeImage"
                    >
                    </app-media-editable>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <h3 class="form-edit-header">{{ $t('video.main_settings') }}</h3>
                    <app-select
                      v-model="video.defaultSite"
                      @blur="$v.video.defaultSite.$touch()"
                      :error="$v.video.defaultSite.$error"
                      :options="sites"
                      id="video_defaultSite"
                      :label="$t('video.default_site')"
                      required
                    >
                    </app-select>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      {{ $v.video.categories }}
                      <label :class="{'error': $v.video.expanded.categories.$error}">
                        {{ $t('video.categories') }}
                        <span class="required">*</span>
                      </label>
                      <app-multi-select
                        v-model="video.expanded.categories"
                        :options="categories"
                        label="title"
                        track-by="id"
                        id="video_categories"
                        data-attr="videoCategories_list"
                      >
                      </app-multi-select>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <label>{{ $t('video.video_show') }}</label>
                    <app-multi-select
                      v-if="videoShows"
                      v-model="video.expanded.videoShows"
                      :options="videoShows"
                      label="title"
                      track-by="id"
                      id="video_videoShows"
                      data-attr="videoVideoShows_list"
                    >
                    </app-multi-select>
                  </div>
                  <div class="col-lg-12">
                    <label>{{ $t('video.video_category') }}</label>
                    <app-multi-select
                      v-if="videoCategories"
                      v-model="video.expanded.videoCategories"
                      :options="videoCategories"
                      label="title"
                      track-by="id"
                      id="video_videoCategories"
                      data-attr="plusCategories_list"
                    >
                    </app-multi-select>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <app-safety-topics
                        :video="video"
                        :error="$v.video.safetyTopics.$error"
                        data-test="video_theme"
                      >
                      </app-safety-topics>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <app-nature-of-the-video
                        :video="video"
                        :error="$v.isNatureOfVideo.$error"
                        data-test="video_type"
                      >
                      </app-nature-of-the-video>
                    </div>
                    <div class="form-group">
                      <app-video-source-type
                        :video="video"
                        :error="$v.isVideoSourceType.$error"
                        data-test="video_source"
                      >
                      </app-video-source-type>
                    </div>
                    <div class="form-group">
                      <app-input
                        :label="$t('video.video_source_gdpr')"
                        v-model.trim="video.meta.gdprSource"
                        :error="$v.video.meta.gdprSource.$error"
                        data-test="video_source_gdpr"
                      >
                      </app-input>
                    </div>
                  </div>
                  <div class="col-lg-12" data-test="video_setting">
                    <div class="form-group">
                      <app-checkbox
                        v-model="video.setting.forAdults"
                        id="video_setting_forAdults"
                        :label="$t('video.setting_for_adults')"
                        data-test="video_setting_for_adults"
                      >
                      </app-checkbox>
                      <app-checkbox
                        v-model="video.setting.productPlacement"
                        id="video_setting_productPlacement"
                        :label="$t('video.setting_product_placement')"
                        data-test="video_setting_product_placement"
                      >
                      </app-checkbox>
                      <app-checkbox
                        v-model="video.setting.exclusive"
                        id="video_setting_exclusive"
                        :label="$t('video.setting_exclusive')"
                        data-test="video_setting_exclusive"
                      >
                      </app-checkbox>
                      <app-checkbox
                        v-model="video.setting.noAds"
                        id="video_setting_noAds"
                        :label="$t('video.setting_no_ads')"
                        data-test="video_setting_no_ads"
                      >
                      </app-checkbox>
                      <app-checkbox
                        v-model="video.setting.dynamicPreview"
                        id="video_setting_dynamicPreview"
                        :label="$t('video.setting_dynamic_preview')"
                        data-test="video_setting_dynamic_preview"
                      >
                      </app-checkbox>
                      <app-input
                        v-if="video.setting.dynamicPreview"
                        v-model="video.setting.dynamicPreviewStart"
                        type="number"
                        :step=1
                        id="video_setting_dynamicPreviewStart"
                        :label="$t('video.setting_dynamic_preview_start')"
                        :error="$v.video.setting.dynamicPreviewStart.$error"
                        data-test="video_setting_dynamic_preview_start"
                      >
                      </app-input>
                      <app-input
                        v-if="video.setting.dynamicPreview"
                        v-model="video.setting.dynamicPreviewDuration"
                        type="number"
                        :step=1
                        id="video_setting_dynamicPreviewDuration"
                        :label="$t('video.setting_dynamic_preview_duration')"
                        :error="$v.video.setting.dynamicPreviewDuration.$error"
                        data-test="video_setting_dynamic_preview_duration"
                      >
                      </app-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <h3 class="form-edit-header">{{ $t('video.headline_video_parameters') }}</h3>
                    <table class="table table-striped">
                      <tbody>
                      <tr v-if="video.liveboxAssetId !==''">
                        <th>{{ $t('video.livebox_asset_id') }}</th>
                        <td>{{ video.liveboxAssetId }}</td>
                      </tr>
                      <tr v-if="video.youtubeId">
                        <th>{{ $t('video.youtube_id') }}</th>
                        <td>{{ video.youtubeId }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('video.video_duration') }}</th>
                        <td>{{ video.videoDuration | formatMilliseconds }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('video.video_width') }}</th>
                        <td>{{ video.videoWidth }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('video.video_height') }}</th>
                        <td>{{ video.videoHeight }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('video.file_size') }}</th>
                        <td>{{ getVideoSize(video.fileSize) }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!-- Video articles --------------------->
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <h3 class="form-edit-header">{{ $t('video.headline_used_in_articles') }}</h3>
                    <p
                      class="alert alert-info"
                      v-if="video.articles.length === 0"
                    >
                      {{ $t('video.video_article_not_found') }}
                    </p>
                    <app-video-articles
                      v-if="video.articles.length > 0"
                      :video="video"
                    >
                    </app-video-articles>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '@/components/ModuleView'
import VideoTypeMixin from '@/components/mixins/valueObject/VideoTypeMixin'
import VideoMixin from '@/components/mixins/VideoMixin'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import Select from '@/components/form/select/Select'
import MultiSelect from '@/components/form/select/MultiSelect'
import Checkbox from '@/components/form/Checkbox'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import { required, minLength, maxLength, minValue, numeric } from 'vuelidate/lib/validators'
import VideoModel from '@/model/Video'
import ButtonDelete from '@/components/shared/ButtonDelete'
import ButtonClose from '@/components/shared/ButtonClose'
import NotifyService from '@/services/NotifyService'
import MediaSelectButton from '@/components/shared/MediaSelectButton'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaUrlButton from '@/components/shared/MediaUrlButton'
import MediaEditable from '@/components/shared/MediaEditable'
import VideoStatus from '@/components/video/VideoStatus'
import VideoAnnotationList from '@/components/video/VideoAnnotationList'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'
import NatureOfTheVideo from '@/components/video/old/NatureOfTheVideoOld'
import VideoSourceType from '@/components/video/old/VideoSourceTypeOld'
import UserService from '@/services/user/UserService'
import EagleVideo from '@/components/shared/EagleVideo'
import VideoCheckStatusButton from '@/components/video/VideoCheckStatusButton'
import SafetyTopics from '@/components/video/old/VideoSafetyTopicsOld'
import PreloaderFull from '@/components/preloader/PreloaderFull'
import CoreApi from '@/api/core'
import VideoArticles from '@/components/video/old/VideoArticlesOld'
import LiveboxUploadButton from '@/components/shared/LiveboxUploadButton'

export default {
  name: 'VideoNewViewOld',
  mixins: [VideoMixin, VideoTypeMixin],
  data () {
    return {
      dataLoaded: true,
      isLoading: false,
      video: this._.cloneDeep(VideoModel),
      richTextEditorConfig: TinyMceConfig.getConfig(),
      method: 'PUT'
    }
  },
  computed: {
    users () {
      return this.$store.getters['user/all']
    },
    videoShows () {
      const videoShows = this.$store.getters['videoShow/all']
      return videoShows.filter(videoShow => {
        if (videoShow.enabled) {
          return videoShow
        }
        if (this.video.videoShows && this.video.videoShows.includes(videoShow.id)) {
          return videoShow
        }
      })
    },
    videoCategories () {
      const videoCategories = this.$store.getters['videoCategory/all']
      return videoCategories.filter(videoCategory => {
        if (videoCategory.enabled) {
          return videoCategory
        }
        if (this.video.videoCategories && this.video.videoCategories.includes(videoCategory.id)) {
          return videoCategory
        }
      })
    },
    sites () {
      return this.$store.getters['site/userSites']
    },
    showVideoUploadButton () {
      if (this.video.status === 'published') {
        return true
      }
      if (this.$v.$invalid) {
        return false
      }
      return true
    },
    categories () {
      return this.$store.getters['category/all']
    },
    isNatureOfVideo () {
      if (this.video.setting.editorialContent ||
        this.video.setting.agencyContent ||
        this.video.setting.marketingContent ||
        this.video.setting.nativeContent
      ) {
        return true
      }
      return ''
    },
    isVideoSourceType () {
      if (this.video.setting.internal || this.video.setting.social || this.video.setting.agency ||
        this.video.setting.camera || this.video.setting.mobilePhone || this.video.setting.slideshow ||
        this.video.setting.show) {
        return true
      }
      return ''
    }
  },
  validations: {
    video: {
      defaultSite: {
        required,
        minValue: minValue(1)
      },
      safetyTopics: {
        required
      },
      setting: {
        dynamicPreviewStart: {
          numeric,
          minValue: minValue(5)
        },
        dynamicPreviewDuration: {
          numeric,
          minValue: minValue(2)
        }
      },
      expanded: {
        categories: {
          required
        },
        authorUsers: {
          required
        }
      },
      meta: {
        title: {
          required,
          minLength: minLength(50),
          maxLength: maxLength(95)
        },
        description: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(300)
        },
        keywords: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(250)
        },
        gdprSource: {
          minLength: minLength(0),
          maxLength: maxLength(255)
        }
      }
    },
    isNatureOfVideo: {
      required
    },
    isVideoSourceType: {
      required
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appTextarea: Textarea,
    appSelect: Select,
    appMultiSelect: MultiSelect,
    appCheckbox: Checkbox,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appNatureOfTheVideo: NatureOfTheVideo,
    appVideoSourceType: VideoSourceType,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaUrlButton: MediaUrlButton,
    appMediaEditable: MediaEditable,
    appButtonCopyToClipboard: BtnCopyToClipboard,
    appVideoStatus: VideoStatus,
    appVideoAnnotationList: VideoAnnotationList,
    appEagleVideo: EagleVideo,
    appVideoCheckStatusButton: VideoCheckStatusButton,
    appSafetyTopics: SafetyTopics,
    appVideoArticles: VideoArticles,
    appPreloaderFull: PreloaderFull,
    appLiveboxUploadButton: LiveboxUploadButton
  },
  methods: {
    getVideo () {
      this.dataLoaded = false
      this.$store.dispatch('video/fetchOne', this.$route.params.id)
        .then(() => {
          this.video = this.$store.getters['video/detail']
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.dataLoaded = true
        })
    },
    async save (redirectToList = false) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.dataLoaded = false
        this.$store.dispatch('video/create', this.prepareRequest(this.video))
          .then(() => {
            if (this.$store.getters['video/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              if (redirectToList) {
                this.$router.push('/videoOld')
              } else {
                this.video = this.$store.getters['video/detail']
                this.$router.push('/videoOld/' + this.video.id + '/edit')
              }
            } else {
              NotifyService.setErrorMessage(this.$store.getters['video/error'])
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.dataLoaded = true
          })
      }
    },
    deleteVideo () {
      this.$store.dispatch('video/deleteRecord', this.video)
        .then(() => {
          if (this.$store.getters['video/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/videoOld')
          } else {
            NotifyService.setErrorMessage(this.$t('video.' + this.$store.getters['video/error']))
          }
        })
        .catch(error => console.log(error))
    },
    getVideoShows () {
      this.$store.dispatch('videoShow/fetchAll')
      this.$store.dispatch('videoCategory/fetchAll')
    },
    prepareRequest (video) {
      if (video.expanded.previewImage) {
        video.previewImage = video.expanded.previewImage.id
      }
      video.authorUsers = this._.map(video.expanded.authorUsers, 'id')
      video.cameraUsers = this._.map(video.expanded.cameraUsers, 'id')
      video.editingUsers = this._.map(video.expanded.editingUsers, 'id')
      video.videoShows = this._.map(video.expanded.videoShows, 'id')
      video.videoCategories = this._.map(video.expanded.videoCategories, 'id')
      video.categories = this._.map(video.expanded.categories, 'id')
      return video
    },
    setImage (medias) {
      medias.forEach(media => {
        this.video.expanded.previewImage = media
        this.video.previewImage = media.id
      })
    },
    removeImage () {
      this.video.expanded.previewImage = {}
      this.video.previewImage = null
    },
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    setVideo (videoId) {
      this.video.status = 'processing'
      this.video.liveboxAssetId = videoId
      this.save(true)
    },
    setYoutube (ooyalaResponse) {
      this.dataLoaded = false
      this.video.ooyalaId = ooyalaResponse.ooyalaId
      this.video.youtubeId = ooyalaResponse.youtubeId
      this.video.status = 'live'
      this.extractFromUrl(ooyalaResponse.thumbnail)
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.dataLoaded = true
          this.save()
        })
    },
    getVideoSize (fileSize) {
      if (fileSize) {
        return Math.round(fileSize * 1000 / 1048576) / 1000 + ' MB'
      } else {
        return ''
      }
    },
    setPreviewImageFromYoutube () {
      if (!this.video.youtubeId) {
        return
      }
      this.dataLoaded = false
      CoreApi().post('/utils/youtube', JSON.stringify({ input: this.video.youtubeId }))
        .then(response => {
          this.extractFromUrl(response.data.youtube.thumbnail_url)
            .finally(() => {
              this.dataLoaded = true
            })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.dataLoaded = true
        })
    }
  },
  created () {
    this.getVideoShows()
  }
}
</script>

<style lang="scss">
.article-info:hover {
  color: #fff;
}
</style>
